/* eslint-disable global-require */
import './assets/css/vendor/bootstrap.min.css';
import './assets/css/vendor/bootstrap.rtl.only.min.css';
import 'react-circular-progressbar/dist/styles.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-image-lightbox/style.css';
import 'video.js/dist/video-js.css';
import './assets/css/custom.css';
import { CometChat } from '@cometchat/chat-sdk-javascript';
import {
  CometChatUIKit,
  UIKitSettingsBuilder,
} from '@cometchat/chat-uikit-react';

import {
  isMultiColorActive,
  defaultColor,
  isDarkSwitchActive,
  cometChatAppId,
  cometChatAuthKey,
  cometChatRegion,
} from './constants/defaultValues';
import {
  getCurrentColor,
  setCurrentColor,
  getCurrentUser,
} from './helpers/Utils';

const currentUser = getCurrentUser();
let UID = null;
if (currentUser) {
  UID = `${currentUser.id}_${currentUser.title
    .toLowerCase()
    .replace(/\s+/g, '_')}`; // consecutive spaces to single '_' underscore
}
const UIKitSettings = new UIKitSettingsBuilder()
  .setAppId(cometChatAppId)
  .setRegion(cometChatRegion)
  .setAuthKey(cometChatAuthKey)
  .subscribePresenceForFriends()
  .build();

CometChatUIKit.init(UIKitSettings)
  .then(() => {
    console.log('Initialization completed successfully');
  })
  .catch(console.log);

CometChat.getLoggedinUser().then(
  (user) => {
    console.log('User:', user);
    if (!user && UID) {
      CometChat.login(UID, cometChatAuthKey).then(
        (user1) => {
          console.log('User Login indexxxxxx:', user1);
        },
        (error) => {
          console.log('Login failed with exception:', { error });
        }
      );
    }
  },
  (error) => {
    console.log('Something went wrong', error);
  }
);

const color =
  isMultiColorActive || isDarkSwitchActive ? getCurrentColor() : defaultColor;
setCurrentColor(color);

const render = () => {
  import(`./assets/css/sass/themes/gogo.${color}.scss`).then(() => {
    require('./AppRenderer');
  });
};
render();
