/* eslint-disable no-unused-expressions */
/* eslint-disable prettier/prettier */
import {
  defaultDirection,
  defaultLocale,
  defaultColor,
  localeOptions,
  themeColorStorageKey,
  themeRadiusStorageKey,
} from '../constants/defaultValues';

export const mapOrder = (array, order, key) => {
  // eslint-disable-next-line func-names
  array.sort(function (a, b) {
    const A = a[key];
    const B = b[key];
    if (order.indexOf(`${A}`) > order.indexOf(`${B}`)) {
      return 1;
    }
    return -1;
  });
  return array;
};

export const getDateWithFormat = () => {
  const today = new Date();
  let dd = today.getDate();
  let mm = today.getMonth() + 1; // January is 0!

  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${dd}.${mm}.${yyyy}`;
};

export const getCurrentTime = () => {
  const now = new Date();
  return `${now.getHours()}:${now.getMinutes()}`;
};

export const getDirection = () => {
  let direction = defaultDirection;

  try {
    if (localStorage.getItem('direction')) {
      const localValue = localStorage.getItem('direction');
      if (localValue === 'rtl' || localValue === 'ltr') {
        direction = localValue;
      }
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getDirection -> error', error);
    direction = defaultDirection;
  }
  return {
    direction,
    isRtl: direction === 'rtl',
  };
};
export const setDirection = (localValue) => {
  let direction = 'ltr';
  if (localValue === 'rtl' || localValue === 'ltr') {
    direction = localValue;
  }
  try {
    localStorage.setItem('direction', direction);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setDirection -> error', error);
  }
};

export const getCurrentColor = () => {
  let currentColor = defaultColor;
  try {
    if (localStorage.getItem(themeColorStorageKey)) {
      currentColor = localStorage.getItem(themeColorStorageKey);
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : getCurrentColor -> error', error);
    currentColor = defaultColor;
  }
  return currentColor;
};

export const setCurrentColor = (color) => {
  try {
    localStorage.setItem(themeColorStorageKey, color);
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentColor -> error', error);
  }
};

export const getCurrentRadius = () => {
  let currentRadius = 'rounded';
  try {
    if (localStorage.getItem(themeRadiusStorageKey)) {
      currentRadius = localStorage.getItem(themeRadiusStorageKey);
    }
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : getCurrentRadius -> error',
      error
    );
    currentRadius = 'rounded';
  }
  return currentRadius;
};
export const setCurrentRadius = (radius) => {
  try {
    localStorage.setItem(themeRadiusStorageKey, radius);
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : setCurrentRadius -> error',
      error
    );
  }
};

export const getCurrentLanguage = () => {
  let language = defaultLocale;
  try {
    language =
      localStorage.getItem('currentLanguage') &&
      localeOptions.filter(
        (x) => x.id === localStorage.getItem('currentLanguage')
      ).length > 0
        ? localStorage.getItem('currentLanguage')
        : defaultLocale;
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : getCurrentLanguage -> error',
      error
    );
    language = defaultLocale;
  }
  return language;
};
export const setCurrentLanguage = (locale) => {
  try {
    localStorage.setItem('currentLanguage', locale);
  } catch (error) {
    console.log(
      '>>>>: src/helpers/Utils.js : setCurrentLanguage -> error',
      error
    );
  }
};

export const getCurrentUser = () => {
  let user = null;
  try {
    user =
      localStorage.getItem('sp_current_user') != null
        ? JSON.parse(localStorage.getItem('sp_current_user'))
        : null;
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js  : getCurrentUser -> error', error);
    user = null;
  }
  return user;
};

export const setCurrentUser = (user) => {
  console.log(user);
  try {
    if (user) {
      localStorage.setItem('sp_current_user', JSON.stringify(user));
    } else {
      localStorage.removeItem('sp_current_user');
    }
  } catch (error) {
    console.log('>>>>: src/helpers/Utils.js : setCurrentUser -> error', error);
  }
};

export const getValueByCode = (data, code) => {
  if (!data || !code) {
    return '';
  }
  const field = data.find((item) => item.code === code);
  return field ? field.value : undefined;
};

export const unixTimestampToCustomFormat = (unixTimestamp) => {
  if (!unixTimestamp) {
    return '';
  }
  const date = new Date(unixTimestamp);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  return `${year}-${month}-${day} ${hours}:${minutes} GMT`;
};

export const unixTimestampToHumanDate = (unixTimestamp) => {
  if (!unixTimestamp) {
    return '';
  }
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const date = new Date(unixTimestamp);
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear().toString().slice(-2);
  return `${day} ${month} ${year}`;
};
export const getStatusFgColorClass = (status) => {
  switch (status) {
    case 'approved':
    case 'QC Approved':
      return 'bg-color-green';
    case 'conditional_approved':
    case 'QC Conditionally Approved':
      return 'bg-color-yellow';
    case 'rejected':
    case 'QC Rejected':
      return 'bg-color-red';
    default:
      return 'bg-color-orange';
  }
};

export const getStatusBgColorClass = (status) => {
  switch (status) {
    case 'approved':
    case 'QC Approved':
      return 'bg-color-light-green';
    case 'conditional_approved':
    case 'QC Conditionally Approved':
      return 'bg-color-light-yellow';
    case 'rejected':
    case 'QC Rejected':
      return 'bg-color-light-red';
    default:
      return 'bg-color-light-orange';
  }
};

export const getQCStatus = (status) => {
  const currentLanguage = getCurrentLanguage();
  switch (status) {
    case 'approved':
      return currentLanguage === 'zh' ? '通过' : 'Approved';
    case 'conditional_approved':
      return currentLanguage === 'zh' ? '有条件通过' : 'Conditionally Approved';
    case 'rejected':
      return currentLanguage === 'zh' ? '不通过' : 'Rejected';
    case 'inprogress':
      return currentLanguage === 'zh' ? '进行中' : 'In Progress';
    default:
      return currentLanguage === 'zh' ? '待定' : 'Pending';
  }
};
export const getFinalQCStatus = (status) => {
  const currentLanguage = getCurrentLanguage();
  switch (status) {
    case 'QC Approved':
      return currentLanguage === 'zh' ? '通过' : 'QC Approved';
    case 'QC Rejected':
      return currentLanguage === 'zh' ? '不通过' : 'QC Rejected';
    case 'QC Conditionally Approved':
      return currentLanguage === 'zh'
        ? '有条件通过'
        : 'QC Conditionally Approved';
    case 'Sent For SASO Test':
      return currentLanguage === 'zh' ? '寄送SASO测试' : 'Sent For SASO Test';
    case 'In Progress':
      return currentLanguage === 'zh' ? '进行中' : 'In Progress';
    case 'New':
      return currentLanguage === 'zh' ? '未开始' : 'New';
    default:
      return currentLanguage === 'zh' ? '未开始' : 'New';
  }
};
export const optionStatusColor = (status) => {
  switch (status) {
    case 'Sent-1':
    case 'Sent-2':
    case 'Sent-3':
      return 'optionstatus-sent-1';
    case 'Pending':
      return 'optionstatus-pending';
    case 'Resubmit':
    case 'Cancelled':
    case 'Rejected':
      return 'optionstatus-resub';
    case 'Approved':
    case 'Conditional Approved':
      return 'optionstatus-approved';
    case 'Cutting, Stitching':
      return 'optionstatus-sent-1';
    case 'ASN Approval':
      return 'asn-approval';
    case 'ASN Pending':
      return 'asn-pending';
    case 'ASN Submitted':
    case 'ASN Approved':
      return 'asn-submitted';
    case 'ASN Resubmit':
      return 'asn-resubmit';
    default:
      return 'optionstatus-req';
  }
};
export const stateColor = (status) => {
  switch (status) {
    case 'Draft':
      return 'postate-d';
    case 'Final':
      return 'postate-p';
    default:
      return 'postate-d';
  }
};

export const getSid = () => {
  const currentUserDetails = JSON.parse(
    localStorage.getItem('sp_current_user')
  );
  return currentUserDetails.id;
};

export const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
